.imageAndRightSideOptions {
  // border: 5px solid yellow;
  display: flex;
  flex-direction: column;
}
.rightSideOptions {
  //   background-color: blue;
  // flex: 1;
}
.formWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  // height: calc(100vh-380px);
  // height: calc(100vh - 100px); //gap around - is REQUIRED
  height: 99vh;
  padding-bottom: 150px;
  // border: 5px dashed blue;
  // margin-bottom: 150px;
  padding-bottom: 100px;
}
.formContent {
  // overflow-y: scroll;
  // border: 5px dashed red;
  // margin-bottom: 150px;
  padding-bottom: 100px;
}
.fieldWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 0 0.2rem;
}
.fieldLabel,
.fieldLabelMultiline {
  margin: 0 5px;
  color: black;
  white-space: nowrap;
}

.dropdown {
  flex: 1;
  margin-top: 0.5rem;
  min-width: 50px;
}
.inputField {
  margin: 5px 0;
  min-width: 5px;
  padding: 5px 0;
}

textarea,
input {
  flex-grow: 1;
}

// .imageAndRightSideOptions img {
//   background-color: grey;
//   border-radius: 50%;
//   height: 6rem;
//   width: 6rem;
//   align-self: center;
// }

// .topPanel {
//   display: flex;
//   justify-content: space-between;
//   button {
//     height: 20px;
//     align-self: center;
//   }
// }

.profileImageSection {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
