.listNameRow,
.createNewListPanel {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px dotted gray;
  border-radius: 5px;
}
.createNewListPanel {
  padding: 15px 2px;
  justify-content: space-between;
}
