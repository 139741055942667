.smallButton {
  width: 100px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.listSummaryTopBar {
  display: flex;
  max-height: 30px;
  align-items: center;
  justify-content: space-between;
}
.listDetails {
  border-collapse: collapse;
  margin-top: 5px;
}

.listDetails th,
.listDetails td {
  text-align: left;
  padding-right: 5px;
}

/* Apply border only to the right and left sides of the second column */
.listDetails tr:nth-child(2) td:nth-child(2),
.listDetails tr:nth-child(1) th:nth-child(2) {
  border-left: 2px solid gray;
  padding-left: 5px;
  // border-right: 2px solid gray;
}
