.capturedImage {
  width: 200px;
  height: 200px;
}

.buttonPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topPanel {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
