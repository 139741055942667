.okButton {
  // background: linear-gradient(to right, #0ecfe0, #bc39f7);
  background-color: #fe662b;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 20px;
  color: white;
  font-weight: bold;
}

.okButton:active {
  background: linear-gradient(to right, #bc39f7, #0ecfe0);
}
