* {
  box-sizing: border-box;
}

.familyBtnText,
.friendsBtnText,
.groomBtnText,
.brideBtnText,
.settingsBtnText,
.helpBtnText {
  display: inline;
  font-size: 12px;
  font-weight: bold;
  // -webkit-text-stroke: 1px black;

  border-radius: 10px;
  // text-shadow: -8px -8px 20px white, 8px -8px 20px white, 8px 8px 20px white,
  //   -8px 8px 20px white;
}
.familyBtnText {
  padding: 0px 30px;
  background-color: white;
  color: red;
}

.friendsBtnText {
  padding: 0px 15px;
  background-color: yellow;
  color: blue;
}

.groomBtnText {
  padding: 0px 20px;
  background-color: aqua;
  color: black;
}

.brideBtnText {
  padding: 0px 20px;
  background-color: green;
  color: white;
}

.settingsBtnText {
  padding: 0px 20px;
  background-color: red;
  color: white;
}

.helpBtnText {
  padding: 0px 30px;
  background-color: brown;
  color: yellow;
}

.familyBtnContainer {
  // border: 1px solid indianred;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.familyBtnTextContainer {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 1em;
  justify-content: center;
  // border: 2px solid black;
}

.dashboardTopLevelContainer {
  height: 100%;
  width: 100%;
}
.dashboardContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  background-color: #e9edf0d3;
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (min-width: 768px) {
  .dashboardContainer {
    display: grid;
    justify-content: space-evenly; //do distribute evenly on big screens
    grid-template-columns: 150px 150px;
  }
}
