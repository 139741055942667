@media screen and (max-width: 768px) {
  .sideMenuContainer {
    display: flex;
    // border: 5px solid white;
    // margin: 2px;
    z-index: 4;
  }

  .leftSection {
    flex-grow: 1;
    background-color: dodgerblue;
    height: 45px; //height of the top blue bar
  }

  .sideMenuWrapper {
    position: absolute;
    // top: 1%; //to move hamIcon down
    width: 100%;
    background-color: rgba(
      $color: #000000,
      $alpha: 0.7
    ); //background of the side bar menu
    display: flex;
    justify-content: flex-end;
  }
  :root {
    --bar-width: 35px;
    --bar-height: 4px;
    --hamburger-gap: 5px;
    --foreground: lightblue;
    --background: rgb(39, 37, 37);
    --hamburger-margin: 10px;
    --animation-timing: 200ms ease-in-out;
    --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
  }

  .hamburgerMenu {
    //3 bar lines
    --x-width: calc(var(--hamburger-height) * 1.41421356237);
    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    width: max-content;
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
  }
  .hamburgerMenu:has(input:checked) {
    --foreground: rgb(10, 10, 10);
    --background: dodgerblue;
  }
  .hamburgerMenu:has(input:focus-visible)::before,
  .hamburgerMenu:has(input:focus-visible)::after,
  .hamburgerMenu input:focus-visible {
    border: 1px solid var(--background);
    box-shadow: 0 0 0 1px var(--foreground);
  }
  .hamburgerMenu::before,
  .hamburgerMenu::after,
  .hamburgerMenu input {
    content: "";
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: var(--foreground);
    border-radius: 9999px;
    transform-origin: left center;
    transition: opacity var(--animation-timing), width var(--animation-timing),
      rotate var(--animation-timing), translate var(--animation-timing),
      background-color var(--animation-timing);
  }
  .hamburgerMenu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
  }
  .hamburgerMenu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / -2);
  }
  .hamburgerMenu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / 2);
  }
  .hamburgerMenu input:checked {
    opacity: 0;
    width: 0;
  }
  .sidebar {
    /*When burger is not clicked*/
    opacity: 0;
    height: auto;
    transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
    padding: 0.5rem 1rem;
    padding-top: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
    background-color: var(--foreground);
    color: var(--background);
    // max-width: 10rem;
    min-height: 100vh;
    display: none;
  }
  .hamburgerMenu:has(input:checked) + .sidebar {
    /*When burger is CLICKED*/
    opacity: 1;
    height: auto;
    overflow: hidden;
    transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
    display: block;
  }

  .sidebar li {
    font-size: 20px;
    color: black;
    z-index: 4;
  }

  .list a {
    text-decoration: none; //remove underline from links
    color: black;
    // background-color: yellow; background of text menu items
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 5px 50px;
    border-radius: 5px;
    margin: 10px 0;
    // height: 50px; //to increase gap b/w side bar menu options
  }

  .list a.active {
    color: blue;
    background-color: lightgreen;
  }
}

.list a.active {
  pointer-events: none; //disable multiple click
}

.homeIconWrapper {
  height: 100%;
  padding-left: 13px;
}
.homeIcon {
  display: inline-flex;
  align-items: center;
  // background-color: yellow;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .hamburgerMenu,
  .homeIconWrapper {
    display: none;
  }
  .sidebar li {
    font-size: 20px;
    color: black;
  }

  .list {
    display: flex;
    gap: 1rem;
    margin: 0.5rem;
  }

  .list a {
    text-decoration: none;
    color: black;
    padding: 5px 5px;
  }

  .list a.active {
    color: lightblue;
    background-color: black;
  }
}
