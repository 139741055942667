.listNameRow,
.createNewListPanel {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px dotted grey;
  border-radius: 5px;
}
.listNameRow {
  margin-bottom: 3px;
  padding: 5px 0 5px 5px;
}
.createNewListPanel {
  padding: 15px 5px;
  justify-content: space-between;
}

.closeButton {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid black;
  background-color: #d1edf8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 8px;
  position: relative;
  top: -30px;
  left: -7%;
}

.disabled {
  opacity: 0.5;
  pointer-events: "none";
}

.newListNameInput {
  padding: 5px 5px;
}

@media screen and (min-width: 768px) {
  .closeButton {
    left: 1%;
  }
}
