.darkRedButton,
.blackButton,
.greenButton,
.yellowButton,
.orangeButton,
.orangeOkButton,
.magentaButton,
.aquaButton,
.grayButton,
.brownButton,
.lightgrayButton,
.purpleButton,
.blueButton {
  color: white;
  display: inline;
  padding: 5px 15px;
  border-radius: 5px;
  margin-right: 5px;
  align-self: right;
  font-weight: bold;
}

.darkRedButton {
  background-color: #d83068;
}
.blackButton {
  background-color: black;
}
.greenButton {
  background-color: #267426;
}
.yellowButton {
  background-color: #fdd201;
  color: black;
}
.purpleButton {
  background-color: #964ad6;
  color: white;
}
.blueButton {
  background-color: #194de6;
}
.aquaButton {
  background-color: aqua;
  color: black;
}
.magentaButton {
  background-color: #bf00ff;
}

.brownButton {
  background-color: brown;
}

.orangeButton,
.orangeOkButton {
  background-color: #ff8000;
  color: black;
}

.orangeOkButton {
  padding: 8px 30px;
  margin: 0 0 1px 1px;
}
.grayButton {
  background-color: #006666;
  color: white;
}
.lightgrayButton {
  background-color: #b9d1d1;
  color: #e9e4e4;
}

.topPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 5px;
  color: black; //font color
  margin: 0px;
  font-weight: bold;
  background-color: #bbdaf9;
}

.rightAligned {
  justify-content: flex-end;
}
.transparentBackground {
  // background-color: #f6f8f9;
  background-color: lightblue;
}

.nearImageBtnWrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.nearImageMultiRowButtonWrapper {
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: center;
  gap: 10px;
}

.optionalFilterStyle {
  margin-top: 20px;
  color: grey;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: end;
}

.userProfileContainer {
  margin: 2% 1%;
  max-width: 400px;
  padding: 20px;
  // border: 1px solid darkslategrey; /* Add a grayish border */
  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
}

.fullName {
  max-width: 100px;
  max-height: 40px;
  font-size: 12px;
  line-height: 1.1;
  text-align: center;
  color: black;
  overflow: hidden;
}

.fullNameWrapper {
  display: flex;
  justify-content: center;
  // background-color: yellow;
}

.tab {
  display: flex;
  // background-color: #f1f1f1;
  outline: none;
  padding: 2px 5px;
  max-height: 45px;
  min-height: 45px;
  transition: 0.3s;
  overflow-y: scroll;
  font-size: 17px;
  scrollbar-width: none;
  /* Hide scrollbar for WebKit (Chrome, Safari) */
  -webkit-overflow-scrolling: touch;
  /* Hide scrollbar for other browsers */
  overflow: -moz-scrollbars-none;
  /* Hide scrollbar for IE/Edge */
  -ms-overflow-style: none;
}

.tabContent {
  padding: 10px 2px;
  border: 1px solid lightblue;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: lightblue;
  min-height: 200px;
  overflow-y: scroll; //must required else using keyboard tab
  //it was hiding the tab buttons above
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 2px solid red;
}

.tabLinks {
  margin-right: 2px;
  border: none;
  flex-shrink: 0;
  padding: 2px 11px;
  cursor: pointer;
  min-width: 20vw;
}

.tabTitle {
  margin-top: 5px;
}

.activeTab {
  background-color: lightblue;
  border-bottom: 0.5vh solid rgb(235, 72, 213);
  transition: 0.1s;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  //horizontal, vertical, blur, spread radius, color
}

.currentlyShowingCount {
  display: inline;
  font-weight: bold;
  color: black;
  padding-right: 3vw;
}

.scrollableContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding: 5px 3px 2px 0px;
}

.centeredH3Message {
  color: black;
  padding: 0 4vw;
}

.pageSmallTitle {
  color: black;
  font-weight: bold;
  padding-left: 2vw;
}

.boxWithBorder {
  border: 1px solid grey;
  display: flex;
  min-height: 40px;
  width: 100%;
  position: relative;
  border-radius: 2px;
  margin: 5px 0 5px 2px;
  padding-right: 5px;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
}

.centeredMessageContainer {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.centeredMessage {
  color: black;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-self: center;
  // max-width: 70vw;
  text-align: center;
  // border: 1px solid red;
  align-items: center;
}

.fieldSetBox {
  margin-top: 10px;
  border: none;
  border-top: 1px solid gray;
}

.bottomSection {
  position: absolute;
  bottom: 3vh;
}
.pageBackground {
  background-color: dodgerblue;
  height: 100vh;
}

.pageTitle {
  color: bisque;
  text-align: center;
  text-decoration: underline;
}

.pageSubmitBtn,
.fieldLabel {
  color: white;
}

.pageSubmitBtn,
.fieldLabel,
.inputField,
link,
p {
  margin-left: 5px;
}

.pageSubmitBtn {
  margin-top: 10px;
  background-color: #e5015c;
  padding: 10px 20px;
  font-weight: bold;
}

.inputField {
  padding: 10px 5px;
  width: 95%;
}

.pendingField {
  width: 85%;
  background-color: darkmagenta;
  margin: 0;
  padding: 5px;
  font-weight: bold;
  font-size: small;
}

.pendingHeading {
  // background-color: yellow;
  font-weight: bold;
  margin-top: 5px;
  padding: 0;
  color: black;
  font-size: small;
}

.note1,
.note2 {
  width: 98%;
  padding: 0;
  margin: 0;
}
.note1 {
  font-size: small;
  font-weight: bold;
}
.note2 {
  font-size: smaller;
  line-height: 1rem;
}
.note3 {
  font-size: x-small;
  margin-left: 1rem;
  line-height: 1rem;
}

.bullet {
  list-style: disc;
}
.circleBullet {
  list-style: circle;
}

.rowFlex {
  color: black;
  display: flex;
  flex-direction: row;
  // background-color: yellow;
  align-content: space-between;
}
.iconWrapper {
  display: flex;
  align-self: center;
  // background-color: red;
  padding: 0 0 0 10px;
}

.eyeIcon {
  display: flex;
  background-color: red;
  position: relative;
  height: 0px;
  right: 5%;
  justify-content: right;
  top: -27px;
  color: black;
}

.grid,
.gridInsideTab {
  --gap: 5rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 0.1rem;
  --line-color: red;
  --bg-color: white;

  display: grid;
  grid-template-columns: auto auto auto;
  gap: var(--line-thickness);
  /* background-color: var(--line-color); */
  // border: 5px dashed red;
  // overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

.grid {
  max-height: calc(100vh - 130px);
  padding-bottom: 100px;
}

//keep gridInsideTab BELOW grid
.gridInsideTab {
  max-height: calc(100vh - 180px);
}

.grid__item {
  padding: 3px 0;
  display: flex;
  flex-direction: column;
  align-items: right; //this will push all items to right, so we have to align-self the image
  justify-content: center;
  // border-left: 1px solid lightblue;
  // border-bottom: 1px solid lightblue;
  background-color: aliceblue;
  position: relative;
}

.grid__item p {
  //only for the 2 lines below image
  // background-color: lightblue;
  font-size: 0.8rem;
  margin: 0;
  line-height: 1rem;
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis;
  color: black;
}

.grid__item:nth-child(3n + 2) {
  border-left: 2px solid lightblue; /* Left border */
  border-right: 2px solid lightblue; /* Right border */
}

.grid__item:nth-child(n + 4) {
  border-top: 2px solid lightblue; /* Top border */
}
