.parentDiv {
  height: 100%;
  width: 100%;
  // border: 2px solid red;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  background-color: #2196f3;
  // padding: 10px;
  width: 100%;
  height: 100%;
}
.gridItem {
  border: 1px solid indianred;
  display: flex;
  align-items: center;
  justify-content: center;
}
