.bgImage {
  height: 100%;
  position: absolute;
  background-color: darkblue;
  width: 100%;
  z-index: -10;
}

.links {
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
}

.loginButton {
  color: white;
  background-color: #04aa6d;
}

.registerButton {
  background-color: #e7e7e7;
  color: black;
}

.languageIconWrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.languageIcon {
  width: 40px;
}
.languageIcon {
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px lightblue;
}

.languageLabel {
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid darkblue;
  background-color: lightgray;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #6fb1e7;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: green;
  border: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

@media (min-width: 600px) {
  .bgImage {
    max-width: 400px;
  }
  .languageIconWrapper {
    color: black;
  }
}
