.wrapper {
  display: flex;
  flex-direction: column;
  // border: 1px solid lightgray;
  // height: 100%;
  width: 100%;
}

.imgDiv {
  align-self: center;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid lightgray;
  margin-bottom: 0.5rem;
}

.imgDiv img {
  width: 100%;
  height: 100%;
}

.bookmarked {
  position: absolute;
  width: 2rem;
  height: 2rem;
  // border-radius: 50%;
  display: flex;
  align-items: center; /* to show centered inside the outer circle*/
  justify-content: center;
  top: 0px;
  right: 0px;
}

.edit,
.view {
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center; /* to show centered inside the outer circle*/
  justify-content: center;
}

.edit {
  width: 2rem;
  height: 2rem;
  top: 1px;
  right: 1px;
}
.view {
  top: 3.5rem;
  right: 1px;
}
.edit {
  color: blue;
}

.view {
  color: blue;
}

.disabledDiv {
  position: relative;
  /* Other styles for parent div */
}

.disabledDiv::after {
  content: "Requested";
  position: absolute;
  top: 0;
  right: 0;
  background-color: green;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0.5;
}

.smallButton {
  height: 20px;
}

.circularCheckbox {
  background-color: yellow;
  border-radius: 50%;
  border: 2px solid yellow;
  margin: 0 0 0 15px;
  position: absolute;
  top: 1px;
}

.lowerButtonPanelWrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  padding: 5px 0;
  background-color: lightblue;
}
.lowerButtonPanel {
  display: flex;
  justify-content: space-between;
}
