/* HelpVideosComponent.module.scss */

.helpVideosContainer {
  padding: 2px;
  display: flex;
}

.videoContainerGrid {
}

.cardView {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: darkolivegreen;
  p {
    margin-top: 0px;
  }
  img {
    border-radius: 5px 5px 0 0;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .videoContainerGrid {
    grid-template-columns: repeat(4, 1fr); /* Two columns */
  }
}
