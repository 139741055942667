.curcularImage {
  background-color: grey;
  border-radius: 50%;
  border: 1px solid lightgray;
  height: 6rem;
  width: 6rem;
  align-self: center;
}

.fullImage {
  width: 100%;
  height: auto;
}

.fullImageBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}
