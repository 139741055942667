/* styles.css */
.editIcon {
  background-color: rgb(
    237,
    240,
    86
  ); /* Set your desired background color here */
  padding: 7px 7px; /* Optional: Add padding for spacing around the icon */
  border-radius: 50%; /* Make the container circular */
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  color: black;
  margin: 0px 5px;
}

.readOnlyFieldContainer {
  display: flex;
  justify-content: space-between;
}

.readOnlyField {
  background-color: lightslategray;
  width: 85%;
  display: flex;
  align-items: center;
  padding: 8px;
}
