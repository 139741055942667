.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background: #000000cc;
}

.modal {
  position: fixed;
  width: 90vw;
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Center the element both vertically and horizontally */

  z-index: 4;
  overflow: hidden;
}

.header {
  background: #0161e7;
  padding: 5px;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
  color: black;
  // background-color: #d1edf8;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.loader2 {
  border: 16px solid #ffffff;
  border-top: 16px solid #075e13;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffffcc; /* Optional: Add a semi-transparent background */
  z-index: 999; /* Optional: Use a high z-index to make sure it's on top */
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.okButton {
  background-color: #fe662b;
  color: white;
}

.action {
  display: flex;
  justify-content: space-between;
}

// -----------------------------------
@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
