.imageBtn {
  align-content: center;
  align-items: center;
  // border: 2px solid red;
  cursor: pointer;
  height: 25vh; //to decrease image width when height is decreased, make height this
  //also it was showing the last row height smaller as compared to first 2 rows, so 33vh won't be good
  //so use 25vh, after that it will again show last row smaller, but that should be fine
  //no device will be that much small in height.
  // overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  // border: 2px solid black;
}

.familyBtnBkColor {
  background-color: aqua;
}

.friendsBtnBkColor {
  background-color: #1982c8;
}
.searchGroomBtnBkColor {
  background-color: #ffb51a;
}
.searchBrideBtnBkColor {
  background-color: #ff0f4c;
}
.settingsBtnBkColor {
  background-color: #7d4dff;
}
.helpBtnBkColor {
  background-color: #20bb18;
}

// text-shadow: -8px -8px 25px red, 8px -8px 25px red, 8px 8px 25px red, -8px 8px 25px red;
