.profilePreview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid lightgray;
}
.profilePreviewContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.originalImageContainer {
  display: grid;
  place-items: center; /* Horizontally and vertically center */
  height: 250px; /* Set your desired height */
  width: 100%; /* Consume available width of screen */
  overflow: hidden; /* Ensure the image doesn't overflow the container */
  background-color: lightblue;
}
.originalImage {
  border: 1px solid red;
  height: 250px; /* Let the height adjust automatically */
}
