.gridxxxx {
  margin-block: 3em;
  grid-row: auto auto auto;
}

.grid__item {
  padding: 3px 0;
  display: flex;
  flex-direction: column;
  align-items: right; //this will push all items to right, so we have to align-self the image
  justify-content: center;
}

.grid {
  --gap: 5rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 0.2rem;
  --line-color: red;
  --bg-color: white;

  display: grid;
  grid-template-columns: auto auto auto;
  gap: var(--line-thickness);
  background-color: lightblue;
}

.grid__item {
  //whole rectangular card having image circle and text lines
  // background-color: var(--bg-color);
  background-color: aliceblue;
  position: relative;
}

.imgDiv {
  align-self: center;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid black;
  margin-bottom: 0.5rem;
}

.imgDiv img {
  width: 100%;
  height: 100%;
}

.grid__item p {
  //only for the 2 lines below image
  // background-color: lightblue;
  font-size: 0.8rem;
  margin: 0;
  line-height: 1rem;
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis;
  color: black;
}

.edit,
.delete {
  position: absolute;
  background-color: lightgreen;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center; /* to show pencil inside the outer circle*/
  justify-content: center;
}

.edit {
  top: 1px;
  right: 1px;
}
.delete {
  top: 3.5rem;
  right: 1px;
}
.edit {
  color: blue;
}

.delete {
  color: red;
}

.pageTitle {
  color: darkblue;
  user-select: none;
  font-weight: bold;
  margin-left: 5px;
}

.editBtn {
  background-color: #fdd201;
  color: black;
  display: inline;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 10px;
  align-self: right;
  font-weight: bold;
}

.deleteBtn {
  background-color: #b23631;
  color: white;
  display: inline;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10px;
  margin-left: 30px;
  align-self: right;
  font-weight: bold;
}
