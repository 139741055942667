.detailsWrapper {
  // background-color: lightblue;
  overflow-y: scroll;
  max-height: 75vh;
  padding: 5px 0;
}
b {
  // border-bottom: 1px dotted lightgray;
  background-color: lightyellow;
}
.topPanelWithImage {
  display: flex;
}
.listInfoSection {
  display: flex;
  gap: 5px;
  align-items: first baseline;
  // background-color: rgb(223, 223, 207);
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-weight: bolder;
}
