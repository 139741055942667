.d1 {
  color: black;
  height: 100%;
  border: 5px solid red;
  display: flex;
  flex-direction: column;
  position: static;
}

.d2 {
  border: 5px dotted darkcyan;
  height: calc(100vh - 120px);
  background-color: aquamarine;
  overflow-y: auto;
}
.d21 {
  border: 5px dotted blue;
  height: 200px;
}

.d3 {
  border: 5px dotted black;
  position: relative;
  bottom: 1px;
  height: 40px;
  background-color: yellow;
}
