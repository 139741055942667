.userProfileContainer {
  margin: 2% 1%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid darkslategrey; /* Add a grayish border */
  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
}

.formGroup {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.inputField {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

textarea {
  resize: none;
}
