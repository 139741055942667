.rootWrapper {
  //this is the root having hamburger and below section etc.
  display: flex;
  flex-direction: column;
  background-color: #f6f8f9;
  height: 100%;
  width: 100%;
  overflow: hidden;
  //   border: 5px dotted red;
  position: fixed;
}

.mainContainer {
  //this is section below hamburger
  display: flex;
  flex-direction: column;
  height: 100%; //45px is height of top hamburger menu bar
  // border: 3px dashed blue;
  overflow-y: hidden;
}
