.filterFieldWrapper {
  width: 100%;
  // background-color: yellow;
  display: flex;
  flex-direction: column;
}
.filterDropdown {
  position: relative;
  display: flex;
  align-items: center;
  // background-color: green;
}
.filterDropdown div {
  margin: 0 1rem;
  // background-color: blue;
}

.fieldLabelMultiline {
  user-select: none;
  margin: 0 5px;
  color: black;
  white-space: nowrap;
}

.tagsContainer {
  position: relative;
  left: 10px;
  width: 100%;
  display: flex;
  align-items: left;
  flex-wrap: wrap;
}

.tagWithCloseButton {
  position: relative;
  // padding: 5px 10px;
  // margin: 0px 5px 5px;
  display: flex;
  direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: green;
}

.tagCloseButton {
  background-color: #9ec9ff;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}

.boxWithBorder {
  border: 1px solid grey;
  display: flex;
  min-height: 80px;
  position: relative;
  border-radius: 10px;
  margin: 5px;
  padding-right: 5px;
  flex-direction: column;
  padding-left: 10px;
}
.titleAndCloseButton {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100%;
  top: -12px;
}
.topLeftBoxTitle {
  background-color: #9eb8f1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.closeButtonWrapper {
  // background-color: #9eb8f1;
  padding: 0;
}
.topRightCloseBtn {
  position: relative;
  background-color: #9eb8f1;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}

.choiceCountAndChooseButton {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.multicolumnCheckboxesWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 100%;
  min-width: 100%;
  background-color: #00000080;
  padding: 20px;
  z-index: 3;
  align-items: center;
  justify-content: center;
}

.matrixGrid {
  max-height: 400px;
  width: 100%;
  max-width: 350px;
  display: grid;
  // border-radius: 10px;
  grid-template-columns: auto auto auto;
  // background-color: #9e7c1f;
  background-color: brown;
  padding: 2px;
  overflow-y: scroll;
}
.matrixGridItem {
  display: flex;
  flex-direction: row;
  border: 1px dotted lightcoral;
  border-collapse: collapse;
  // background-color: blue;
  min-height: 50px;
  cursor: pointer;
  align-items: center;
  padding-left: 2px;
}
.matrixGridItem div {
  margin-left: 2px; //space between circle check and gotraName
}
.buttonPanel {
  width: 100%;
  display: flex;
  max-width: 350px;
  justify-content: center;
  color: black;
  font-weight: bold;
  background-color: lightblue;
}
// .optionTextWrapper {
//   // display: flex;
// }
