.addBalanceSection,
.balanceSection,
.transactionHistorySection {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.balanceSection {
  color: black;
  padding: 20px 10px;
  font-weight: bold;
  // align-items: space-between;
}
.balanceDigits {
  background-color: green;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  color: white;
}

.addBalanceSection {
  margin-top: 50px;
  align-items: center;
  padding: 25px 10px 20px;
  justify-content: center;
}
.transactionHistorySection {
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 5px;
}

.balanceAndHistoryWrapper,
.addBalanceSection {
  border: 1px solid #b6b6b6;
}

.balanceAndHistoryWrapper {
  padding-bottom: 10px;
}

.historyTableContainer {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.historyTable {
  font-family: arial, sans-serif;
  font-size: 11px;
  border-collapse: collapse;
  width: 330px;
  height: 350px;
}
.historyTable thead {
  background-color: #acb3f5;
}

.historyTable td,
.historyTable th {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-align: left;
  padding: 5px 0px;
}

.historyTable tr:nth-child(even) {
  background-color: #e6e2e2;
}
