.userDetailsContainer {
  margin: 2% 1%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #657575; /* Add a grayish border */
  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
  box-shadow: 0 0 20px #00000033, /* Outer shadow */ 0 0 5px #0000001a; /* Inner shadow */
}

.topPanel {
  display: flex;
  justify-content: space-between;
  button {
    height: 20px;
    align-self: center;
  }
}
