.boxStyle {
  width: 200px;
  height: 50px;
}

.disableScroll {
  height: 100vh; //only when height is fixed scroll works
  overflow: hidden;
  background-color: lightgray;
}

.enableScroll {
  height: 100vh;
  overflow: scroll;
  background-color: green;
}
